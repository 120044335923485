import React from 'react'
import PropTypes from 'prop-types'

const DESCUENTO = 0.2;

const Precios = ({ data }) => (
  <div className="columns">
    {data.map(price => (
      <div key={price.item} className="column">
        <section className="section">
          <h4 className="has-text-centered has-text-weight-semibold">
            {price.item}
          </h4>
          <h2 className="is-size-2 has-text-weight-bold has-text-primary has-text-centered">
            {DESCUENTO > 0.0 ? <div style={{textDecoration: 'line-through', fontSize: '60%', color: '#666'}}><br/>
              ${typeof price.precio === 'number' ? price.precio.toLocaleString('es-CL') : price.precio}
            </div> : null}
            ${typeof price.precio === 'number' ? (price.precio * (1 - DESCUENTO)).toLocaleString('es-CL') : price.precio}<span style={{fontSize: '40%', color: '#666'}}> {DESCUENTO > 0.0 ? `-${Math.floor(DESCUENTO * 100)}%` : 'CLP'} </span>
          </h2>
          <p className="has-text-weight-semibold">{price.descripcion}</p>
          {/* <ul>
            {price.items.map(item => (
              <li key={item} className="is-size-5">
                {item}
              </li>
            ))}
          </ul> */}
        </section>
      </div>
    ))}
  </div>
)

Precios.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.string,
      precio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      descripcion: PropTypes.string,
    })
  ),
}

export default Precios
