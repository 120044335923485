import React from 'react'
import PropTypes from 'prop-types'
// import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Precios from '../components/Precios'

export const SingleProductTemplate = ({
  content,
  contentComponent,
  description,
  title,
  precios,
  helmet,
  logo,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <center>
              <div style={{maxWidth: 256, marginBottom: 25}}>
                <PreviewCompatibleImage imageInfo={logo} />
              </div>
            </center>
            <p>{description}</p>
            <PostContent content={content} />
            <Precios data={precios} />
            <h3>Para adquirir este producto, contáctate con nosotros al correo <a href="mailto:ventas@psicointel.cl">ventas@psicointel.cl</a>.</h3>
          </div>
        </div>
      </div>
    </section>
  )
}

SingleProductTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  precios: PropTypes.array,
  // precios: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     item: PropTypes.string,
  //     precio: PropTypes.string
  //   })
  // )
}

const SingleProduct = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SingleProductTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        // tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        precios={post.frontmatter.precios}
        logo={post.frontmatter.logo}
      />
    </Layout>
  )
}

SingleProduct.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default SingleProduct

export const pageQuery = graphql`
  query ProductById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        precios {
          item
          precio
          descripcion
        }
        logo {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 526, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
